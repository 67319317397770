























































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      messages: [],
      operations: {
        notIn: false,
        or: true,
        and: false,
      },
      messageDate: {
        from: "",
        to: "",
      },
    });

    const fetchMessages = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/${root.$route.params.id}/select`)
        .then(({ data: { messages } }) => {
          state.messages = messages;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.messages = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchMessages);

    const selectAllMessages = () => {
      props.value.message.values = state.messages.map((el: any) => el.id);
    };

    watch(
      () => state.operations.or,
      () => {
        if (state.operations.or) {
          props.value.message.operation = "or";
          state.operations.and = false;
        }
      }
    );

    watch(
      () => state.operations.notIn,
      () => {
        if (state.operations.or && state.operations.notIn) {
          props.value.message.operation = "notInWithOr";
          state.operations.and = false;
        } else if (state.operations.notIn) {
          props.value.message.operation = "notInWithOr";
          state.operations.and = false;
          state.operations.or = true;
        } else if (!state.operations.notIn && state.operations.or) {
          props.value.message.operation = "or";
        }
      }
    );

    watch(
      () => state.operations.and,
      () => {
        if (state.operations.and) {
          props.value.message.operation = "and";
          state.operations.notIn = false;
          state.operations.or = false;
        } else if (!state.operations.and && state.operations.notIn) {
          props.value.message.operation = "notInWithOr";
        }
      }
    );

    watch(
      () => state.messageDate,
      () => {
        if (state.messageDate.from || state.messageDate.to) {
          props.value.messageDate.values = [
            state.messageDate.from ? state.messageDate.from : undefined,
            state.messageDate.to ? state.messageDate.to : undefined,
          ];
        } else props.value.messageDate.values = [];
      },
      { deep: true }
    );

    return { state, selectAllMessages };
  },
});
